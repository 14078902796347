
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PendingRequests extends Vue {
  public fields = [
    'Site',
    'Tank',
    'Custom Fields',
    'Low Level Alert',
    'Status'
  ];
  public pendingRequests = [
    {
      site: {
        name: 'Priorz Apairies Ltd',
        address: '2407 State Highway 29, Lower Kaimai, 3171',
        contactPerson: 'Rob & Lisa Prior',
        phone: '0274696635',
        email: 'logistics@southfuel.co.nz',
        additionalAccess: false
      },
      tank: [
        {
          size: 1900,
          fuelType: 'Petrol',
          tankType: 'Overhead',
          tankManufact: 'Mainline'
        },
        {
          size: 1900,
          fuelType: 'Diesel',
          tankType: 'Overhead',
          tankManufact: 'Mainline'
        }
      ],
      customFields: {
        customerNum: 17804,
        manager: 'Wayne Hurunui',
        region: 'BOP/Waikato',
        runNum: 'WAI 53 Kaimai'
      },
      lowLevelAlert: {
        email: ['logistics@southfuel.co.nz']
      },
      status: 'Pending'
    },
    {
      site: {
        name: 'Sample name',
        address: '227 Sample address, Sampletown, 1002',
        contactPerson: 'Sample and Elpmas Sampleson ',
        phone: '01234567',
        email: 'sample@samplemail.com',
        additionalAccess: true
      },
      tank: [
        {
          size: 1500,
          fuelType: 'Petrol',
          tankType: 'Overhead',
          tankManufact: 'Mainline'
        }
      ],
      customFields: {
        customerNum: 10002,
        manager: 'Sampler Relpmas',
        region: 'Southland',
        runNum: 'STH 333'
      },
      lowLevelAlert: {
        email: ['sample@samplemail.com', 'anothersample@mailg.co.nz']
      },
      status: 'Pending'
    }
  ];
  show = false;
  ////This is for a table instead of cards
  //   public items() {
  //     const items = this.pendingRequests.map(request => {
  //       return {
  //         site: this.getSite(request.site),
  //         tank: this.getTanks(request.tank),
  //         customFields: Object.entries(request.customFields).map(value => {
  //           return value[0] + ': ' + value[1];
  //         }).join('\n'),
  //         lowLevelAlert: this.getLowLevel(request.lowLevelAlert.email),
  //         status: request.status
  //       };
  //     });
  //     return items;
  //   }

  //   public getSite(site: {
  //     name: string;
  //     address: string;
  //     contactPerson: string;
  //     phone: string;
  //     email: string;
  //     additionalAccess: boolean;
  //   }) {
  //     return (
  //       'Name: ' +
  //       site.name +
  //       '\nAddress: ' +
  //       site.address.replaceAll(',', '\n') +
  //       '\nContact Person: ' +
  //       site.contactPerson +
  //       '\nPhone: ' +
  //       site.phone +
  //       '\nEmail: ' +
  //       site.email +
  //       (site.additionalAccess ? '\nAdditional Access' : '')
  //     );
  //   }

  //   public getTanks(
  //     tanks: {
  //       size: number;
  //       fuelType: string;
  //       tankType: string;
  //       tankManufact: string;
  //     }[]
  //   ) {
  //     let data = '';
  //     tanks.forEach(tank => {
  //       data +=
  //         'Size: ' +
  //         tank.size +
  //         '\nFuel Type: ' +
  //         tank.fuelType +
  //         '\nTank Type: ' +
  //         tank.tankType +
  //         '\nTank Manufacturer: ' +
  //         tank.tankManufact +
  //         '\n\n';
  //     });
  //     return data;
  //   }

  //   public getLowLevel(email: string[]) {
  //     let data = '';
  //     email.forEach(address => {
  //       data += address + '\n';
  //     });
  //     return data;
  //   }

  public goBack() {
    this.$router.push('/milk-view');
  }
}
